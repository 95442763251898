import React from 'react';

import PageStatic from '../../components/page-static';
import BookCPDForm from '../../components/contact/book-a-cpd-form';

function BookCPDPage(props) {
  return (
    <PageStatic seoTitle={'Contact us. Book a CPD'} {...props} noCtaBlock>
      <BookCPDForm />
    </PageStatic>
  );
}

export default BookCPDPage;
